import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './components/Home';
import Layout from './Layout';
import Payments from './components/Reports/Payments';
import Login from './components/Login';
import Logout from './components/Logout';
import {CookiesProvider} from 'react-cookie';
import {ToastContainer} from "react-toastify";
import {payments, topups} from "./loaders/reports";
import Topup from './components/Reports/Topup';
import Balance from './components/Reports/Balance';
import Vouchers from './components/Reports/Vouchers';
import Roles from './components/Roles';
import RolesView from './components/Roles/View';
import AllTransactions from "./components/Reports/All";

const router = createBrowserRouter([
  {
    element: <Login/>,
    path: "/login",
  },
  {
    element: <Logout/>,
    path: "/logout",
  },
  {
    element: <Layout errorElement={'...'}/>,
    children: [
      {
        path:"/",
        element: <Home/>
      },
      {
        path:"/reports/all",
        element: <AllTransactions/>,
      },
      {
        path:"/reports/payments",
        element: <Payments/>,
        loader: payments
      },
      {
        path:"/reports/balance",
        element: <Balance/>,
      },
      {
        path:"/reports/topups",
        element: <Topup/>,
        loader: topups
      },
      {
        path:"/reports/vouchers",
        element: <Vouchers/>,
      },
      {
        path:"/roles",
        element: <Roles/>,
      },
      {
        path:"/roles/view",
        element: <RolesView/>,
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <ToastContainer/>
    <RouterProvider router={router}/>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
