import { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import { Search, ODD, EVEN, axios, dateDiff } from "../../utils";
import Modal from "../../Modal";
import Datepicker from "tailwind-datepicker-react";
import ArrowLeft from "../../images/arrow-left.svg";
import ArrowRight from "../../images/arrow-right.svg";
import useUserStore from "../../stores/user";

const modalStyle = {
    content: {
        width: 500,
        height: 380,
        margin: 'auto',
        top: '-30%'
    }
}

export default function Vouchers(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(searchParams.get('type')) || '';
    const [sort, setSort] = useState(searchParams.get('sort') || 'date');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState();
    const location = useLocation();
    const [inputTimeout, setInputTimeout] = useState();
    const [page, setPage] = useState(1);
	const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [filename, setFilename] = useState('Filename.xls');
    const [vouchers, setVouchers] = useState({});
    const [modalOpen, setModalOpen] = useState();
    const user = useUserStore(state=>state.user);
    const navigate = useNavigate();

	const handleStartClose = (state) => {
		setShowStart(state)
	}
	const handleEndClose = (state) => {
		setShowEnd(state)
	}

    useEffect(()=>{
        if(vouchers){
            setLoading(true);
            setTimeout(()=>setLoading(false), 1000)
        }
    }, [vouchers])

    useEffect(() => {
        clearTimeout(inputTimeout);
        setInputTimeout(setTimeout(() => {
            const type = searchParams.get('type') || ''
            setSearchParams({sort, type, search, page: page || 1})
        }, 500))
    }, [search])

    const fetchData = async()=>{
        const url = new URL(document.location);
        const type = url.searchParams.get("type") || '';
        const sort = url.searchParams.get("sort");
        const search = url.searchParams.get("search") || '';
        const page = url.searchParams.get("page") || 1;
        if(user && (
            (type=='redeemed' && !user['redeemed-vouchers']?.includes('view'))
            || (type=='' && !user['all-vouchers']?.includes('view'))
        )){
            toast.error("You are not allowed to view the page.");
            navigate('/')
        }
        try {
            const resp = await axios.get(`reports/vouchers?page=${page}&type=${type}&sort=${sort}&search=${search}`);
            setVouchers(resp.data)
        }catch(err){
            toast.error(err.response?.data || err.message)
            return {}
        }
        
    }

    useEffect(() => {
        const type = searchParams.get('type') || ''
        setType(type)
        fetchData();
    }, [location])

    useEffect(() => { 
        const type = searchParams.get('type') || ''
        setSearchParams({sort, type, search, page: page || 1})
    }, [sort, page])

    const onSort = (sort)=>{
        setSort(sort);
    }

    const onExport = ()=>{
        if(new Date(end) < new Date(start)){
            toast.error("Invalid Date.");
            return;
        }
        if(new Date(start) > new Date() || new Date(end) > new Date()){
            toast.error("Future date is not allowed.")
            return;
        }
        if(dateDiff(new Date(start), new Date(end))>60){
            toast.error("Date range must be within 60 days only.")
            return;
        }
        
        axios.get(`reports/vouchers${window.location.search}&start=${start.getTime()}&end=${end.getTime()}&export=1`, {responseType:'blob'})
            .then((resp)=>{
                const href = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err=>{
                console.error(err)
                toast.error('Failed to download report');
            })
    }

    const onClickExport = ()=>{
        if(user &&
            (type=='redeemed' && !user['redeemed-vouchers']?.includes('view'))
            || (type=='' && !user['all-vouchers']?.includes('view'))
        ){
            toast.error("You are not allowed to do this action.");
            navigate('/')
        }
        setModalOpen(true)
    }

    return <>
        <div className="flex justify-between">
            <h1 className="font-semibold text-2xl ">{type ?? 'ALL'} VOUCHERS</h1>
            <button onClick={onClickExport} class="block bg-green rounded-full text-white py-1 px-10">
            EXPORT
            </button>
        </div>
        <div className="pagemenu bg-pagemenu rounded p-4 mt-7 flex justify-between">
            <ul className="cursor-pointer text-lg pt-1">
                <li>SORT:</li>
                <li onClick={()=>onSort('date')} className={`${sort=='date' ? 'underline' : 'text-gray-400'}`}>DATE</li>
                <li onClick={()=>onSort('batch_name')} className={`${sort=='batch_name' ? 'underline' : 'text-gray-400'}`}>BATCH NAME</li>
                <li onClick={()=>onSort('amount')} className={`${sort=='amount' ? 'underline' : 'text-gray-400'}`}>AMOUNT</li>
            </ul>
            <Search value={search} onChange={e=>setSearch(e.target.value)}/>
        </div>
        <table className="mt-4 table-auto w-[100%]">
            <thead>
                <tr>
                    <th>Batch Name</th>
                    <th>Voucher Type</th>
                    <th>Redemption Type</th>
                    <th>Number of Vouchers</th>
                    <th>Voucher Code</th>
                    <th>Value of Vouchers</th>
                    <th>Expiry</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th>Date and Time</th>
                    <th>Redeemed by</th>
                </tr>
            </thead>
            <tbody>
                {loading && <div className="loader" />}
                {loading === false && vouchers.rows?.map((p, i)=><tr key={p.id}>
                    <ODD i={i}>{p.batch_name}</ODD>
                    <EVEN i={i}>{p.type}</EVEN>
                    <ODD i={i}>{p.redemption_type}</ODD>
                    <EVEN i={i}>{p.total_qty}</EVEN>
                    <ODD i={i}>{p.code}</ODD>
                    <EVEN i={i}>{p.amount}</EVEN>
                    <EVEN i={i}>{p.expiredAt && new Date(p.expiredAt.replace(/-/g, "/")).toISOString().split('T')[0]}</EVEN>
                    <ODD i={i}>{p.status}</ODD>
                    <ODD i={i}>{p.description}</ODD>
                    <EVEN i={i}>{new Date(p.createdAt.replace(/-/g, "/")).toISOString().split('T')[0]}</EVEN>
                    <ODD i={i}>{p.redeemed_by}</ODD>
                </tr>)}
            </tbody>
        </table>
        <div className="flex justify-center pt-6">
            <ul className="pagination">
                {vouchers.totalPages>=4 && <li className=" "><img onClick={()=>setPage((prev)=>prev-1)} className="inline cursor-pointer" src={ArrowLeft}/></li>}
                {
                    Array(vouchers.totalPages).fill('').map((x,i)=> {
                        return (i+3>=page && i+2 < page+4) && <li key={i} className={`cursor-pointer px-3 ${(i+1)==page  && 'active rounded'}`} onClick={()=>setPage(i+1)}>{i+1}</li>
                    })
                }
                {vouchers.totalPages>=4 && <li className="pl-7"><img onClick={()=>setPage((prev)=>prev+1)} className="inline cursor-pointer" src={ArrowRight}/></li>}
            </ul>
        </div>
        <Modal style={modalStyle} isOpen={modalOpen} onRequestClose={()=>setModalOpen(false)}>
            <p className="text-2xl text-center">Export</p>
            <div className="text-sm text-gray text-center mt-5">You can export maximum of 60 days worth of data</div>
            <div className="space-y-2">
                <div>Select Date</div>
                <div className="flex flex-between space-x-4">
                    <Datepicker options={{defaultDate:new Date()}} onChange={setStart} setShow={handleStartClose} show={showStart}/>
                    <Datepicker options={{defaultDate:new Date()}} onChange={setEnd} setShow={handleEndClose} show={showEnd}/>
                </div>
                <div>File Name</div>
                <input onChange={(e)=>setFilename(e.target.value)} value={filename} type="text" className="w-full rounded"/>
                <div className="space-y-2">
                    <button disabled={!filename} onClick={onExport} class="m-auto block bg-green rounded-full text-white py-1 px-10">
                        EXPORT
                    </button>
                    <button onClick={()=>setModalOpen(false)} className="m-auto block rounded-full border bg-green-outline py-1 px-10">
                        CLOSE
                    </button>
                </div>
            </div>
        </Modal>
    </>
}