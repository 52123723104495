import { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Search, ODD, EVEN, axios, dateDiff } from "../../utils";
import {toast} from "react-toastify"
import ArrowLeft from "../../images/arrow-left.svg";
import ArrowRight from "../../images/arrow-right.svg";
import Modal from "../../Modal";
import Datepicker from "tailwind-datepicker-react";
import useUserStore from "../../stores/user";

const modalStyle = {
    content: {
        width: 500,
        height: 380,
        margin: 'auto',
        top: '-30%'
    }
}

export default function All(){
    const [transactions, setTransactions] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort, setSort] = useState(searchParams.get('sort') || 'date');
    const [type, setType] = useState(searchParams.get('type') || 'daily');
    const [search, setSearch] = useState('');
    const [inputTimeout, setInputTimeout] = useState();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState();
    const location = useLocation();
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [modalOpen, setModalOpen] = useState();
    const [filename, setFilename] = useState('Filename.xls');
	const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [fetchTimeout, setFetchTimeout] = useState();
    const user = useUserStore(state=>state.user);
    const navigate = useNavigate();

    const getTransactions = () => {
        clearTimeout(fetchTimeout);
        setTransactions({rows:[]});
        setFetchTimeout(setTimeout(()=>{
            setLoading(true);
            const url = new URL(document.location);
            const sort = url.searchParams.get("sort");
            const page = url.searchParams.get("page") || 1;
            const search = url.searchParams.get("search") || '';
            const type = url.searchParams.get("type") || 'daily';
            if(user && !user['all-transactions']?.includes('view')){
                toast.error("You are not allowed to view the page.");
                navigate('/')
            }
            axios.get(`reports/all?sort=${sort}&search=${search}&page=${page}&type=${type}`)
                .then((resp)=>{
                    setTransactions(resp.data);
                    setLoading(false)
                })
                .catch(err=>{
                    toast.error(err.response?.data || err.message)
                    setLoading(false)
                })
        }, 1000))
    }

    useEffect(()=>{
        getTransactions();
    }, [type, type, sort, page, search, location])

	const handleStartClose = (state) => {
		setShowStart(state)
	}
	const handleEndClose = (state) => {
		setShowEnd(state)
	}

    const onSort = (sort)=>{
        setSort(sort);
    }

    useEffect(() => {
        const type = searchParams.get('type')
        setType(type)
    }, [location])

    useEffect(() => {
        clearTimeout(inputTimeout);
        setInputTimeout(setTimeout(() => {
            const type = searchParams.get('type')
            setSearchParams({sort, type, search})
        }, 500))
    }, [search])

    useEffect(() => { 
        const type = searchParams.get('type')
        setSearchParams({sort, type, search, page})
    }, [sort, page]);

    const onExport = ()=>{
        if(new Date(end) < new Date(start)){
            toast.error("Invalid Date.");
            return;
        }
        if(new Date(start) > new Date() || new Date(end) > new Date()){
            toast.error("Future date is not allowed.")
            return;
        }
        if(dateDiff(new Date(start), new Date(end))>60){
            toast.error("Date range must be within 60 days only.")
            return;
        }
        axios.get(`reports/all${window.location.search}&limit=10000&start=${start.getTime()}&end=${end.getTime()}&export=1`, {responseType:'blob'})
            .then((resp)=>{
                const href = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err=>{
                console.error(err)
                toast.error('Failed to download report');
            })
    }

    const onClickExport = ()=>{
        if(user && !user['all-transactions']?.includes('extract')){
            toast.error("You are not allowed to do this action.");
            return
        }
        setModalOpen(true)
    }

    return <>
        <div className="flex justify-between">
            <h1 className="font-semibold text-2xl ">ALL TRANSACTIONS</h1>
            <button onClick={onClickExport} target="_blank" className="bg-green rounded-full text-white py-1 px-10">EXPORT</button>
        </div>
        <div className="pagemenu bg-pagemenu rounded p-4 mt-7 flex justify-between">
            <ul className="cursor-pointer text-lg pt-1">
                <li>SORT:</li>
                <li onClick={()=>onSort('date')} className={`${sort=='date' ? 'underline' : 'text-gray-400'}`}>DATE</li>
                <li onClick={()=>onSort('branch')} className={`${sort=='branch' ? 'underline' : 'text-gray-400'}`}>BRANCH</li>
                <li onClick={()=>onSort('amount')} className={`${sort=='amount' ? 'underline' : 'text-gray-400'}`}>AMOUNT</li>
            </ul>
            <Search value={search} onChange={e=>setSearch(e.target.value)}/>
        </div>
        <table className="mt-4 table-auto w-[100%]">
            <thead>
                <tr>
                    <th>Transaction ID</th>
                    <th>Mobile Number</th>
                    <th>Transaction Type</th>
                    <th>SKU</th>
                    <th>Amount</th>
                    <th>Branch</th>
                    <th>Recipient</th>
                    <th>Order Status</th>
                    <th>Mode of Payment</th>
                    <th>Date</th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {loading && <div className="loader" />}
                {loading === false && transactions.rows.map((p, i)=><tr key={p.id}>
                    <ODD i={i}>{p.reference_number}</ODD>
                    <EVEN i={i}>{p.phone_number}</EVEN>
                    <ODD i={i}>{p.transaction_type}</ODD>
                    <EVEN i={i}>{p.sku}</EVEN>
                    <ODD i={i}>{p.amount_paid}</ODD>
                    <EVEN i={i}>{p.name}</EVEN>
                    <ODD i={i}>{p.recipient}</ODD>
                    <EVEN i={i}>{p.status}</EVEN>
                    <ODD i={i}>{p.payment_method}</ODD>
                    <EVEN i={i}>{new Date(p.createdAt).toISOString().split('T')[0]}</EVEN>
                    <ODD i={i}>{p.timestamp}</ODD>
                </tr>)}
            </tbody>
        </table>
        <div className="flex justify-center pt-6">
            <ul className="pagination">
                {transactions.totalPages>=4 && <li className=" "><img onClick={()=>setPage((prev)=>prev-1)} className="inline cursor-pointer" src={ArrowLeft}/></li>}
                {
                    Array(transactions.totalPages).fill('').map((x,i)=> {
                        return (i+3>=page && i+2 < page+4) && <li key={i} className={`cursor-pointer px-3 ${(i+1)==page  && 'active rounded'}`} onClick={()=>setPage(i+1)}>{i+1}</li>
                    })
                }
                {transactions.totalPages>=4 && <li className="pl-7"><img onClick={()=>setPage((prev)=>prev+1)} className="inline cursor-pointer" src={ArrowRight}/></li>}
            </ul>
        </div>
        <Modal style={modalStyle} isOpen={modalOpen} onRequestClose={()=>setModalOpen(false)}>
            <p className="text-2xl text-center">Export</p>
            <div className="text-sm text-gray text-center mt-5">You can export maximum of 60 days worth of data</div>
            <div className="space-y-2">
                <div>Select Date</div>
                <div className="flex flex-between space-x-4">
                    <Datepicker options={{defaultDate:new Date()}} onChange={setStart} setShow={handleStartClose} show={showStart}/>
                    <Datepicker options={{defaultDate:new Date()}} onChange={setEnd} setShow={handleEndClose} show={showEnd}/>
                </div>
                <div>File Name</div>
                <input onChange={(e)=>setFilename(e.target.value)} value={filename} type="text" className="w-full rounded"/>
                <div className="space-y-2">
                    <button disabled={!filename} onClick={onExport} class="m-auto block bg-green rounded-full text-white py-1 px-10">
                        EXPORT
                    </button>
                    <button onClick={()=>setModalOpen(false)} className="m-auto block rounded-full border bg-green-outline py-1 px-10">
                        CLOSE
                    </button>
                </div>
            </div>
        </Modal>
    </>
}
