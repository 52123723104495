import {axios} from "../../utils";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadPhoto from "../../images/upload-photo.svg";
import ArrowRight from "../../images/arrow-square-right.svg"
import ArrowDown from "../../images/arrow-square-down.svg"
import Checkbox from "../../images/checkbox-green.svg";
import useUserStore from "../../stores/user";

export default function View(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState({});
    const [toggle, setToggle] = useState({});
    const [permissions, setPermissions] = useState({})
    const currentUser = useUserStore(state=>state.user);
    const labels = [
        {
            id:'sales-data',label:'Sales Data',
            children: [
                {id:'sales-data',label:'Sales Data'},
            ]
        },
        {
            id:'marketing-tool', label: 'Marketing Tool',
            children: [
                {id:'voucher',label:'Voucher'},
                {id:'promos',label:'Promos'},
                {id:'referral-program',label:'Referral Program'},
            ]
        },
        {
            id:'wallet', label: 'Wallet Eco System',
            children: [
                {id:'mother-wallet',label:'Mother Wallet'},
                {
                    id:'conversion-settings',label:'Conversion Settings',
                    children: [
                        {id:'topup-issuance-rate', label: 'Top Up Issuance Rate'},
                        {id: 'reward-issuance-rate', label: 'Reward Issuance Rate'},
                        {id:'redemption-rate', label: 'Redemption Rate'},
                    ]
                },
                {id:'top-up',label:'Top Up'},
                {id:'reward-settings',label:'Reward Settings'},
                {id:'voucher-wallet-settings',label:'Voucher Wallet Settings'},
            ]
        },
        {
            id:'menu-database', label: 'Menu Database',
            children: [
                {id: 'menu-database', label: 'Menu Database'},
            ]
        },
        {
            id:'branches', label: 'Branches',
            children: [
                {id:'branches', label: 'Branches'},
            ]
        },
        {
            id:'staffs', label: 'Staffs',
            children: [
                {id:'staffs', label: 'Staffs'},
            ]
        },
        {
            id:'reports', label: 'Reports',
            children: [
                {
                    id:'transaction-report', label: 'Transaction Report',
                    children: [
                        {id:'all-transactions', label: 'All Transactions'},
                        {id:'daily-payments-transactions', label: 'Daily Payments Transactions'},
                        {id:'month-to-date-payments-transactions', label: 'Month to Date Payments Transactions'},
                        {id:'daily-topup-transactions', label: 'Daily Top Up Transactions'},
                        {id:'month-to-date-topup-transactions', label: 'Month to Date Top Up Transactions'},
                    ]
                },
                {
                    id: 'balance-report', label: 'Balance Report',
                    children: [
                        {id: 'mother-wallet-balance', label: 'Mother Wallet Balance'},
                        {id: 'as-of-today-user-balances', label: 'As of Today User Balances'},
                        {id: 'as-of-today-branch-balances', label: 'As of Today Branch Balances'},
                    ]
                },
                {
                    id: 'vouchers', label: 'Vouchers',
                    children: [
                        {id: 'all-vouchers', label: 'All Vouchers'},
                        {id: 'redeemed-vouchers', label: 'Redeemed Vouchers'},
                    ]
                }
            ]
        },
        {
            id:'roles', label: 'Roles & Permissions',
            children: [
                {id:'roles', label: 'Roles & Permissions'}
            ]
        },
    ];
    const getUser = async()=>{
        axios.get(`/roles/${searchParams.get('id')}`)
            .then((resp)=>{
                setUser(resp.data);
                try{
                    setPermissions(JSON.parse(resp.data.permissions))
                }catch(err){
                    console.error(err)
                }
            })
            .catch(err=>toast.error(err.response?.data || err.message))
    }

    const onChange = (e, id, action)=>{
        const {checked} = e.target;
        setPermissions(prev=>({...prev, [id]: (checked ? [...(prev[id] ?? []), action] : [...prev[id].filter(a=>a!=action)]) }))
    }

    const onToggle = id=>{
        setToggle((prev)=>({...prev, [id]: !prev[id]}));
    }

    const savePermission = async()=>{
        await axios.post('/roles/permission', {email:user.email, permissions: JSON.stringify(permissions)})
        toast("Permission has been saved.")
    }

    useEffect(()=>{
        getUser();
    }, []);

    const render = (c,i, l, className)=>{
        return <tr key={i}>
                <td className={className}>{c.label}</td>
                <td className="text-center cursor-pointer">
                    {permissions[c.id]?.includes('view') && <img width={20} onClick={()=>onChange({target:{checked:false}}, c.id, 'view')} className="m-auto" src={Checkbox}/>}
                    {!permissions[c.id]?.includes('view') && <input checked={permissions[c.id]?.includes('view')} onChange={e=>onChange(e, c.id, 'view')} type="checkbox"/>}
                </td>
                <td className="text-center">
                    {permissions[c.id]?.includes('extract') && <img width={20} onClick={()=>onChange({target:{checked:false}}, c.id, 'extract')} className="m-auto" src={Checkbox}/>}
                    {!permissions[c.id]?.includes('extract') && <input checked={permissions[c.id]?.includes('extract')} onChange={e=>onChange(e, c.id, 'extract')} type="checkbox"/>}
                </td>
                <td className="text-center">
                    {permissions[c.id]?.includes('create') && <img width={20} onClick={()=>onChange({target:{checked:false}}, c.id, 'create')} className="m-auto" src={Checkbox}/>}
                    {!permissions[c.id]?.includes('create') && <input checked={permissions[c.id]?.includes('create')} onChange={e=>onChange(e, c.id, 'create')} type="checkbox"/>}
                </td>
                <td className="text-center">
                    {permissions[c.id]?.includes('edit') && <img width={20} onClick={()=>onChange({target:{checked:false}}, c.id, 'edit')} className="m-auto" src={Checkbox}/>}
                    {!permissions[c.id]?.includes('edit') && <input checked={permissions[c.id]?.includes('edit')} onChange={e=>onChange(e, c.id, 'edit')} type="checkbox"/>}
                </td>
                <td className="text-center">
                    {permissions[c.id]?.includes('archive') && <img width={20} onClick={()=>onChange({target:{checked:false}}, c.id, 'archive')} className="m-auto" src={Checkbox}/>}
                    {!permissions[c.id]?.includes('archive') && <input checked={permissions[c.id]?.includes('archive')} onChange={e=>onChange(e, c.id, 'archive')} type="checkbox"/>}
                </td>
        </tr>
    }

    const renderChildren = (c,i, l)=>{
        return <>
            <tr><td colSpan={6}>{c.label}</td></tr>
            {c.children.map((d,i)=>render(d, i, d, "pl-[100px] w-[400px]"))}
        </>
    }

    const onClickSave = ()=>{
        if(currentUser && !currentUser['roles']?.includes('edit')){
            toast.error("You are not allowed to do this action.");
        }
        savePermission()
    }
    
    return <>
    <div className="flex justify-between">
        <div className="flex justify-start space-x-2">
            <div className="text-center cursor-pointer mb-[-25px]">
                <img src={UploadPhoto}/>
                <div className="w-[10px] relative text-gray-400 text-sm top-[-55px] left-[13px]">Upload &nbsp;Photo</div>
            </div>
            <div>
                <h1 className="font-semibold text-2xl">{user.first_name} {user.last_name}</h1>
                <span className="text-green">{user.job_title} <span className="text-[25px] leading-3 relative top-[5px] text-gray">&#8226;</span> <span className="text-gray">{user.email}</span></span>
            </div>
        </div>
        <div className="flex justify-between space-x-2 h-[35px]">
            <button onClick={onClickSave} class="block bg-green rounded-full text-white py-1 px-10">
                SAVE
            </button>
            <Link to="/roles" class="inline rounded-full border-green text-green py-1 px-10">
                BACK
            </Link>
        </div>
    </div>
    <table className="table-auto w-[100%]">
        <thead>
            <tr>
                <th>Permission</th>
                <th>View</th>
                <th>Extract</th>
                <th>Create</th>
                <th>Edit</th>
                <th>Archive</th>
            </tr>
        </thead>
        <tbody>
            {
                labels.map((l, i)=>{
                    return <>
                        <tr key={i} onClick={()=>onToggle(l.id)} className={`${toggle[l.id] && 'bg-darkgreen'} cursor-pointer`}>
                            <td colSpan={6}>
                                <div className="flex justify-between">
                                    <span>{l.label}</span>
                                    <img src={toggle[l.id] ? ArrowDown : ArrowRight}/>
                                </div>
                            </td>
                        </tr>
                            {
                                toggle[l.id] && l.children?.map((c,i)=>{
                                    if(c.children) return renderChildren(c, i, l) 
                                    else return render(c, i, l)
                                })
                            }
                    </>
                })
            }
        </tbody>
    </table>
    </>
}