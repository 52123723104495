import Modal from "../../Modal";
import { useEffect, useState } from "react";
import {axios} from "../../utils";
import { toast } from "react-toastify";
import { users as getUsers } from "../../loaders/users";
import { Link } from "react-router-dom";
import useUserStore from "../../stores/user";
import { useNavigate } from "react-router-dom";

const modalStyle = {
    content: {
        width: 400,
        height: 530,
        margin: 'auto',
        top: '-20%'
    }
}

export default function Roles(){
    const [modalOpen, setModalOpen] = useState();
    const [form, setForm] = useState({});
    const [roles, setRoles] = useState([]);
    const user = useUserStore(state=>state.user);
    const navigate = useNavigate();

    const fetchData = async()=>{
        const response = await axios.get('/roles');
        setRoles(response.data);
    }

    useEffect(() => {
        if(user && !user['roles']?.includes('view')){
            toast.error("You are not allowed to view the page.");
            navigate('/')
        }
        fetchData();
    }, [])

    const onSubmit = () => {
        axios.post('/roles', form)
            .then(()=>{
                toast('Role has been added.');
                setModalOpen(false);
                fetchData();
                setForm({});
            })
            .catch(err=>toast.error(err.response?.data || err.message));
    }

    const onChange = (e) => {
        const {value, name} = e.target;
        setForm((prev)=>({...prev, [name]: value}))
    }

    const onClickOpen = ()=>{
        if(user && !user['roles']?.includes('create')){
            toast.error("You are not allowed to do this action.");
            navigate('/')
        }
        setModalOpen(true)
    }
    
    return <>
        <div className="flex justify-between">
            <h1 className="font-semibold text-2xl ">ROLES & PERMISSIONS</h1>
            <button onClick={onClickOpen} class="block bg-green rounded-full text-white py-1 px-10">
                ADD ACCOUNT
            </button>
        </div>
        <table className="mt-4 table-auto w-[100%]">
            <thead>
                <tr>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Roles</th>
                    <th>Date Created</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    roles.map((u, i)=><tr id={i}>
                        <td>{u.email}</td>
                        <td>{`${u.first_name} ${u.last_name}`}</td>
                        <td>{u.job_title}</td>
                        <td>{u.createdAt}</td>
                        <td>
                        <Link to={`/roles/view?id=${u.id}`} class="text-center block bg-green rounded-full text-white text-sm py-1">
                            VIEW / EDIT PERMISSIONS
                        </Link>
                        </td>
                    </tr>)
                }
            </tbody>
        </table>
        <Modal style={modalStyle} onRequestClose={()=>setModalOpen(false)} isOpen={modalOpen}>
            <h1 className="text-2xl text-center mb-10">Add Account</h1>
            <div className="space-y-2">
                <div className="text-bold">Email</div>
                <input onChange={onChange} name="email" value={form.email} type="email" className="rounded w-full border-gray-500"/>
                <div className="text-bold">First Name</div>
                <input onChange={onChange} name="first_name" value={form.first_name} type="text" className="rounded w-full border-gray-500"/>
                <div className="text-bold">Last Name</div>
                <input onChange={onChange} name="last_name" value={form.last_name} type="text" className="rounded w-full border-gray-500"/>
                <div className="text-bold">Position</div>
                <select value={form.position} onChange={onChange} name="position" className="w-full rounded">
                    <option value="">- Select -</option>
                    <option>Admin</option>
                    <option>Manager</option>
                </select>
                <br/><br/>
                <button onClick={onSubmit} class="block bg-green m-auto rounded-full text-white py-1 px-10">
                    ADD
                </button>
            </div>
        </Modal>
    </>
}