import { Outlet, Link, useLocation, useSearchParams, useNavigate } from "react-router-dom"
import Logo from "./images/logo.png";
import Menu from "./images/menu-toggle.png";
import MenuArrow from "./images/menu-arrow.svg";
import Vertical from "./images/menu-vertical.svg";
import "./layout.css";
import { useEffect, useState } from "react";
import { axios } from "./utils";
import useUserStore from "./stores/user";

export default function Layout(){
    const [active, setActive] = useState();
    const [subActive, setSubActive] = useState();
    const [subPage, setSubPage] = useState();
    const user = useUserStore(state=>state.user);
    const setUser = useUserStore(state=>state.setUser);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(!active && location){
            let _subActive = null;
            const type = searchParams.get('type');
            switch(location.pathname){
                case '/reports/topups':
                case '/reports/payments':
                case '/reports/all':
                    setActive('report');
                    _subActive = 'transactions';
                    break;
                case '/reports/balance':
                    setActive('report');
                    _subActive = 'balance';
                    break;
                case '/roles':
                    setActive('roles');
                    break;
                default:
                    setActive('');
            }
            switch(true){
                case location.pathname=='/reports/all':
                    setSubPage('allTransactions')
                    break;
                case location.pathname=='/reports/payments' && type=='daily':
                    setSubPage('dailyPayments')
                    break;
                case location.pathname=='/reports/payments' && type=='monthly':
                    setSubPage('monthlyPayments')
                    break;
                case location.pathname=='/reports/topups' && type=='daily':
                    setSubPage('dailyTopup')
                    break;
                case location.pathname=='/reports/topups' && type=='monthly':
                    setSubPage('monthlyTopup')
                    break;
                case _subActive=='balance' && type=='mother':
                    setSubPage('balanceMother')
                    break;
                case _subActive=='balance' && type=='user':
                    setSubPage('balanceUser')
                    break;
                case _subActive=='balance' && type=='branch':
                    setSubPage('balanceBranch')
                    break;
            }

            setSubActive(_subActive)
        }
    }, [location])

    useEffect(()=>{
        axios.post('/')
            .then(resp=>{
                if(!resp.data?.email) navigate('/login')
                else setUser(resp.data)
            })
            .catch((err)=>{
                //toast.error('Failed to authenticate')
                if(err.response.status==403) navigate('/login')
            })
    }, [])

    return <>
        <div className="flex justify-between text-white" style={{backgroundColor:"#171717"}}>
            <img src={Logo} className="h-[56px]"/>
            <div className="gap-2">
                <span>Hello {user?.email},</span>
                <Link to="/logout" className="border-white border rounded-full pl-2 pr-2 ml-2">LOG OUT</Link>
                <img className="inline h-[56px]" src={Menu}/>
            </div>
        </div>
        <div className="grid md:grid-cols-12 h-full">
            <aside className="md:col-span-2 text-white uppercase"  style={{backgroundColor:"#75894b"}}>
                <div className="p-2 menu border-b disabled">
                    <Link className="flex justify-between">
                        Sales Data
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className="p-2 menu border-b disabled ">
                    <Link className="flex justify-between">
                        Marketing Tool
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className="p-2 menu border-b disabled ">
                    <Link className="flex justify-between">
                        Wallet Eco System
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className="p-2 menu border-b disabled ">
                    <Link className="flex justify-between">
                        Menu Database
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className="p-2 menu border-b disabled ">
                    <Link className="flex justify-between">
                        Branches
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className={`p-2 menu ${active=='staff'?'active':'border-b'}`}>
                    <Link onClick={()=>setActive('staff')} className="flex justify-between">
                        Staff
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                <div className={`p-2 menu ${active=='report'?'active':'border-b'}`}>
                    <Link onClick={()=>setActive('report')} className="flex justify-between">
                        Report
                        <img src={MenuArrow}/>
                    </Link>
                </div>
                {
                    active == 'report' && <div className="submenu">
                        <div className="p-2 menu ">
                            <Link onClick={()=>setSubActive('transactions')} className={`flex justify-between pl-2 ${subActive=='transactions'&&'text-white'}`}>
                                Transactions Report
                                <img src={MenuArrow}/>
                            </Link>
                        </div>
                        {
                            subActive=='transactions' && <div className="text-green">
                                <div>
                                    <Link to="/reports/all" onClick={()=>setSubPage('allTransactions')} className={`flex justify-between pl-6 ${subPage=='allTransactions'?'text-white':''}`}>
                                        {subPage=='allTransactions' && <img className="pr-2" src={Vertical}/>}
                                        All Transactions
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/payments?type=daily" onClick={()=>setSubPage('dailyPayments')} className={`flex justify-between pl-6 ${subPage=='dailyPayments'?'text-white':''}`}>
                                        {subPage=='dailyPayments' && <img className="pr-2" src={Vertical}/>}
                                        Daily Payments Transactions
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div> 
                                    <Link to="/reports/payments?type=monthly" onClick={()=>setSubPage('monthlyPayments')} className={`flex justify-between pl-6 ${subPage=='monthlyPayments'?'text-white':'pl-6'}`}>
                                        {subPage=='monthlyPayments' && <img className="pr-2" src={Vertical}/>}
                                        Month to Date Transactions
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/topups?type=daily" onClick={()=>setSubPage('dailyTopup')} className={`flex justify-between pl-6 ${subPage=='dailyTopup'?'text-white':'pl-6'}`}>
                                        {subPage=='dailyTopup' && <img className="pr-2" src={Vertical}/>}
                                        Daily Top Up Transactions
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/topups?type=monthly" onClick={()=>setSubPage('monthlyTopup')} className={`flex justify-between pl-6 ${subPage=='monthlyTopup'?'text-white':'pl-6'}`}>
                                        {subPage=='monthlyTopup' && <img className="pr-2" src={Vertical}/>}
                                        Month to date Top Up Transactions
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                            </div>
                        }
                        <div className="p-2 menu">
                            <Link onClick={()=>setSubActive('balance')} className={`flex justify-between pl-2 ${subActive=='balance'&&'text-white'}`}>
                                    Balance Report
                                    <img src={MenuArrow}/>
                            </Link>
                        </div>
                        {
                            subActive =='balance' && <div className="text-green">
                                <div>
                                    <Link to="/reports/balance?type=mother" onClick={()=>setSubPage('balanceMother')} className={`flex justify-between pl-6 ${subPage=='balanceMother'?'text-white':'pl-6'}`}>
                                        {subPage=='balanceMother' && <img className="pr-2" src={Vertical}/>}
                                        MOTHER WALLET BALANCE
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/balance?type=user" onClick={()=>setSubPage('balanceUser')} className={`flex justify-between pl-6 ${subPage=='balanceUser'?'text-white':'pl-6'}`}>
                                        {subPage=='balanceUser' && <img className="pr-2" src={Vertical}/>}
                                        AS OF TODAY USER BALANCES
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/balance?type=branch" onClick={()=>setSubPage('balanceBranch')} className={`flex justify-between pl-6 ${subPage=='balanceBranch'?'text-white':'pl-6'}`}>
                                        {subPage=='balanceBranch' && <img className="pr-2" src={Vertical}/>}
                                        AS OF TODAY BRANCH BALANCES
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                            </div>
                        }
                        <div className="p-2 menu ">
                            <Link onClick={()=>setSubActive('vouchers')} className={`flex justify-between pl-2 ${subActive=='vouchers'&&'text-white'}`}>
                                Vouchers Report
                                <img src={MenuArrow}/>
                            </Link>
                        </div>
                        {
                            subActive =='vouchers' && <div className="text-green">
                                <div>
                                    <Link to="/reports/vouchers" onClick={()=>setSubPage('all-vouchers')} className={`flex justify-between pl-6 ${subPage=='all-vouchers'?'text-white':'pl-6'}`}>
                                        {subPage=='all-vouchers' && <img className="pr-2" src={Vertical}/>}
                                        ALL VOUCHERS
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/reports/vouchers?type=REDEEMED" onClick={()=>setSubPage('redeemed-vouchers')} className={`flex justify-between pl-6 ${subPage=='redeemed-vouchers'?'text-white':'pl-6'}`}>
                                        {subPage=='redeemed-vouchers' && <img className="pr-2" src={Vertical}/>}
                                        REDEEMED VOUCHERS
                                        <img src={MenuArrow} className="pr-2"/>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className={`p-2 menu ${active=='roles'?'active':'border-b'}`}>
                    <Link to="/roles" className="flex justify-between">
                        ROLES & PERMISSIONS
                        <img src={MenuArrow}/>
                    </Link>
                </div>
            </aside>
            <main className="md:col-span-10 p-6"><Outlet/></main>
        </div>
    </>
}