import { useEffect } from "react";
import useUserStore from "../stores/user";
import {useNavigate} from "react-router-dom";
import {axios} from "../utils";
import {toast} from "react-toastify";

export default function Logout(){
    const setUser = useUserStore(state=>state.setUser);
    const navigate = useNavigate();

    useEffect(()=>{
        axios.get('/logout')
            .then(()=>{
                setUser({});
                navigate('/login');
            })
            .catch((err)=>toast.error(err.response?.data || err.message))
    }, [])

    return <>Logging out..</>
}