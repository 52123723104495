import { toast } from "react-toastify";
import {axios} from "../utils";

export const vouchers = async({request})=>{
    const url = new URL(request.url);
    const type = url.searchParams.get("type") || '';
    const sort = url.searchParams.get("sort");
    const search = url.searchParams.get("search") || '';
    const page = url.searchParams.get("page") || 1;
    try {
        const resp = await axios.get(`reports/vouchers?page=${page}&type=${type}&sort=${sort}&search=${search}`);
        return resp.data;
    }catch(err){
        toast.error(err.response?.data || err.message)
        return {}
    }
}

export const balances = async({request})=>{
    const url = new URL(request.url);
    const type = url.searchParams.get("type") || 'mother';
    const sort = url.searchParams.get("sort");
    const search = url.searchParams.get("search") || '';
    const page = url.searchParams.get("page") || 1;
    try {
        const resp = await axios.get(`reports/balances?page=${page}&type=${type}&sort=${sort}&search=${search}`);
        return resp.data;
    }catch(err){
        toast.error(err.response?.data || err.message)
        return {}
    }
}

export const payments = async({request})=>{
    const url = new URL(request.url);
    const sort = url.searchParams.get("sort");
    const page = url.searchParams.get("page") || 1;
    const search = url.searchParams.get("search") || '';
    const type = url.searchParams.get("type") || 'daily';
    try{ 
        const resp = await axios.get(`reports/payments?sort=${sort}&search=${search}&page=${page}&type=${type}`);
        return resp.data
    }catch(err){
        return []
    }
}

export const topups = async({request})=>{
    const url = new URL(request.url);
    const sort = url.searchParams.get("sort");
    const page = url.searchParams.get("page") || 1;
    const search = url.searchParams.get("search") || '';
    const type = url.searchParams.get("type") || 'daily';

    try{
        const resp = await axios.get(`reports/topups?sort=${sort}&search=${search}&page=${page}&type=${type}`);
        return resp.data
    }catch(err){
        return []
    }
}