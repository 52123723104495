import instance from 'axios';
import IconSearch from "./images/icon-search.svg";

export const ODD = ({i, children})=><td className={`${i%2!=0&&'bg-td-odd'}`}>{children}</td>
export const EVEN = ({i, children})=><td className={`${i%2!=0&&'bg-td-even'}`}>{children}</td>


export const dateDiff= function(d1, d2) {
    const t2 = d2.getTime();
    const t1 = d1.getTime();
    return parseInt((t2-t1)/(24*3600*1000));
}

export const Search = ({value, onChange})=>{
    return <div>
        <input value={value} onChange={onChange} type="text" placeholder="Search" className="rounded-full h-[35px] w-72 pl-7 border-gray-400"/>
        <div className="relative -top-6 left-2 -mb-8 flex w-4 text-gray-300">
            <img src={IconSearch}/>
        </div>
    </div>
}

export const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length >= 2) return parts.pop().split(';').shift();
}

export const axios = instance.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        common: {Authorization: `Bearer ${getCookie('token')}`}
    }
});

