import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Search, ODD, EVEN, axios, dateDiff } from "../../utils";
import {toast} from "react-toastify";
import ArrowLeft from "../../images/arrow-left.svg";
import ArrowRight from "../../images/arrow-right.svg";
import Modal from "../../Modal";
import Datepicker from "tailwind-datepicker-react";
import {balances as getBalances} from "../../loaders/reports";
import useUserStore from "../../stores/user";

const modalStyle = {
    content: {
        width: 500,
        height: 380,
        margin: 'auto',
        top: '-30%'
    }
}

const MotherBalance = ({balances, loading, setLoading})=>{
    const [userBalance, setUserBalance] = useState(0);
    const [branchBalance, setBranchBalance] = useState(0);
    const [loader, setLoader] = useState({user:true, branch: true});

    useEffect(() => {
        axios.get(`reports/balances?type=user&page=1&limit=1000000&search=`)
            .then((resp)=>{
                setLoader((prev)=>({...prev,user:false}))
                const userBalance = resp.data.rows.reduce((acc, cur)=>acc+parseFloat(cur.balance), 0);
                setUserBalance(userBalance)
            })
        axios.get(`reports/balances?type=branch&page=1&limit=1000000&search=`)
            .then((resp)=>{
                setLoader((prev)=>({...prev,branch:false}))
                const branchBalance = resp.data.rows.reduce((acc, cur)=>acc+parseFloat(cur.balance), 0);
                setBranchBalance(branchBalance)
            })
    }, [])

    const onExport = ()=>{
        axios.get(`reports/balances${window.location.search}&export=1`, {responseType:'blob'})
            .then((resp)=>{
                const href = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `Mother Balance Report as of today.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err=>{
                console.error(err)
                toast.error('Failed to download report');
            })
    }

    return (<>
        <div className="flex justify-between">
            <h1 className="font-semibold text-4xl">
                MOTHER WALLET BALANCE
            </h1>
            <button onClick={onExport} target="_blank" className="bg-green rounded-full text-white py-1 px-10 hidden">EXPORT</button>
        </div>
        <div className="text-xl pt-9">
            TOTAL ISSUED BALANCE:
            <div className="text-4xl">{!loader.branch && !loader.user && parseFloat(userBalance+branchBalance).toFixed(2)}</div>
            {loader.branch && loader.user && <div className="loader" />}
        </div>
        <div className="text-xl pt-7">
            MOTHER WALLET BALANCE:
            <div className="text-4xl">{!loading && parseFloat(balances.motherBalance).toFixed(2)}</div>
            {loading && <div className="loader" />}
        </div>
        <div className="text-xl pt-9">
            TOTAL NUMBER OF USER BALANCES:
            <div className="text-4xl">{!loader.user && parseFloat(userBalance).toFixed(2)}</div>
            {loader.user && <div className="loader" />}
        </div>
        <div className="text-xl pt-9">
            TOTAL NUMBER OF BRANCH BALANCES:
            <div className="text-4xl">{!loader.branch&& parseFloat(branchBalance).toFixed(2)}</div>
            {loader.branch && <div className="loader" />}
        </div>
    </>);
}

const Table = ({balances, type}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort, setSort] = useState(searchParams.get('sort') || 'date');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState();
    const [page, setPage] = useState(1);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [modalOpen, setModalOpen] = useState();
    const [filename, setFilename] = useState('Filename.xls');
	const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const user = useUserStore(state=>state.user);

	const handleStartClose = (state) => {
		setShowStart(state)
	}
	const handleEndClose = (state) => {
		setShowEnd(state)
	}

    useEffect(() => {
        setSearchParams({sort, search, page, type})
    }, [sort, page, type, search])

    const onSort = (sort)=>{
        setSort(sort);
    }

    useEffect(()=>{
        if(balances){
            setLoading(true);
            setTimeout(()=>setLoading(false), 1000)
        }
    }, [balances])

    const onExport = ()=>{
        if(new Date(end) < new Date(start)){
            toast.error("Invalid Date.");
            return;
        }
        if(new Date(start) > new Date() || new Date(end) > new Date()){
            toast.error("Future date is not allowed.")
            return;
        }
        if(dateDiff(new Date(start), new Date(end))>60){
            toast.error("Date range must be within 60 days only.")
            return;
        }
        axios.get(`reports/balances${window.location.search}&limit=1000000&start=${start.getTime()}&end=${end.getTime()}&export=1`, {responseType:'blob'})
            .then((resp)=>{
                const href = URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(err=>{
                console.error(err)
                toast.error('Failed to download report');
            })
    }

    const onClickExport = () => {
        if(user && ((type=='user' && !user['as-of-today-user-balances']?.includes('extract'))
            || (type=='branch' && !user['as-of-today-branch-balances']?.includes('extract')))
        ){
            toast.error("You are not allowed to do this action.");
            return
        }
        setModalOpen(true)
    }

    return <>
        <div className="flex justify-between">
            <h1 className="font-semibold text-4xl">
                {type=='user'?'AS OF TODAY USER': 'AS OF TODAY BRANCH'} BALANCE
            </h1>
            <button onClick={onClickExport} target="_blank" className="bg-green rounded-full text-white py-1 px-10">EXPORT</button>
        </div>
        <div className="pagemenu bg-pagemenu rounded p-4 mt-7 flex justify-between">
            <ul className="cursor-pointer text-lg pt-1">
                <li>SORT:</li>
                <li onClick={()=>onSort('date')} className={`${sort=='date' ? 'underline' : 'text-gray-400'}`}>DATE</li>
                <li onClick={()=>onSort('branch')} className={`${sort=='branch' ? 'underline' : 'text-gray-400'}`}>BRANCH</li>
                <li onClick={()=>onSort('amount')} className={`${sort=='amount' ? 'underline' : 'text-gray-400'}`}>AMOUNT</li>
            </ul>
            <Search value={search} onChange={e=>setSearch(e.target.value)}/>
        </div>
        <table className="mt-4 table-auto w-[100%]">
            <thead>
                <tr>
                    <th>Branch ID/Mobile</th>
                    <th>Balance</th>
                    <th>Date</th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {loading && <div className="loader" />}
                {(balances.rows && loading === false) && balances.rows.map((p, i)=><tr key={p.id}>
                    <ODD i={i}>{p.reference_id}</ODD>
                    <EVEN i={i}>{p.balance}</EVEN>
                    <EVEN i={i}>{new Date(p.createdAt.replace(/\s+/g, 'T')).toISOString().split('T')[0]}</EVEN>
                    <ODD i={i}>{p.createdAt}</ODD>
                </tr>)}
            </tbody>
        </table>
        <div className="flex justify-center pt-6">
            <ul className="pagination">
                {balances.totalPages>=4 && <li className=" "><img onClick={()=>setPage((prev)=>prev-1)} className="inline cursor-pointer" src={ArrowLeft}/></li>}
                {
                    Array(balances.totalPages).fill('').map((x,i)=> {
                        return (i+3>=page && i+2 < page+4) && <li key={i} className={`cursor-pointer px-3 ${(i+1)==page  && 'active rounded'}`} onClick={()=>setPage(i+1)}>{i+1}</li>
                    })
                }
                {balances.totalPages>=4 && <li className="pl-7"><img onClick={()=>setPage((prev)=>prev+1)} className="inline cursor-pointer" src={ArrowRight}/></li>}
            </ul>
        </div>
        <Modal style={modalStyle} isOpen={modalOpen} onRequestClose={()=>setModalOpen(false)}>
            <p className="text-2xl text-center">Export</p>
            <div className="text-sm text-gray text-center mt-5">You can export maximum of 60 days worth of data</div>
            <div className="space-y-2">
                <div>Select Date</div>
                <div className="flex flex-between space-x-4">
                    <Datepicker options={{defaultDate:new Date()}} onChange={setStart} setShow={handleStartClose} show={showStart}/>
                    <Datepicker options={{defaultDate:new Date()}} onChange={setEnd} setShow={handleEndClose} show={showEnd}/>
                </div>
                <div>File Name</div>
                <input onChange={(e)=>setFilename(e.target.value)} value={filename} type="text" className="w-full rounded"/>
                <div className="space-y-2">
                    <button disabled={!filename} onClick={onExport} class="m-auto block bg-green rounded-full text-white py-1 px-10">
                        EXPORT
                    </button>
                    <button onClick={()=>setModalOpen(false)} className="m-auto block rounded-full border bg-green-outline py-1 px-10">
                        CLOSE
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default function Balance(){
    const [balances, setBalances] = useState({totalIssued: 0, motherBalance: 0});
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [type, setType] = useState('mother');
    const [loading, setLoading] = useState();
    const user = useUserStore(state=>state.user);
    const navigate = useNavigate();

    const getData = async()=>{
        const request = {url: document.location.href};
        const data = await getBalances({request});
        setBalances(data)
        setLoading(false)
    }

    useEffect(()=>{
        setLoading(true)
        const type = searchParams.get('type') || 'mother'
        if(user && (
            (type=='mother' && !user['mother-wallet-balance']?.includes('view'))
            || (type=='user' && !user['as-of-today-user-balances']?.includes('view'))
            || (type=='branch' && !user['as-of-today-branch-balances']?.includes('view'))
        )){
            toast.error("You are not allowed to view the page.");
            navigate('/')
        }
        setType(type)
        getData();
    }, [location])

    const render = ()=>{
        switch(type){
            case 'branch':
            case 'user':
                return <Table type={type} balances={balances}/>;
            case 'mother':
            default:
                return <MotherBalance setLoading={setLoading} loading={loading} balances={balances}/>;
        }
    }

    return <>
        {render()}
    </>
}