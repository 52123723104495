import { toast } from "react-toastify";
import {axios} from "../utils";

export const users = async()=>{
    try{
        const resp = await axios.get('/roles');
        return resp.data;
    }catch(err){
        toast.error(err.response?.data || err.message)
        return []
    }
}